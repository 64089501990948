/*
 * @description: 
 * @Author: HaoJG
 * @Date: 2021-08-09 10:48:34
 * @Lasteditors: HaoJG
 * @LastEditTime: 2022-02-14 09:57:51
 */
const whiteList = [
    '/login',
    '/aicaigou'
]

export default whiteList