/*
 * @Author: minihorse
 * @Date: 2020-12-09 15:30:00
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-02-14 09:49:11
 * 
 */
const Layout = () => import('../pages/layout')
// const Mobile = () => import('../pages/layout/mindex')
const staticRoutes = [
    {
        path: '/',
        redirect: '/login'
    },    
    {
        path: '/login',
        component: () => import(/* webpackChunkName:'second' */ '../pages/login')
    },
    {
        path: '/aicaigou',
        component: () => import(/* webpackChunkName:'second' */ '../pages/aicaigou')
    },
    /* 本段代码放在最后，未设置路由的地址跳转404 */
    {
        path:'*',
        redirect: '/404'
    },
    {
        path: '/404',
        component: Layout,
        children: [
            {
                path: '',
                meta: {
                    title: '页面不存在'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/404')
            },
        ]
    },
    /** 本段代码放在最后，未设置路由的地址跳转404 */
]
export default staticRoutes