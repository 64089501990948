// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import axios from 'axios'
// import './assets/common.css'
import qs from 'qs'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

Vue.config.productionTip = false
Vue.prototype.$qs = qs

axios.interceptors.request.use(function (config) {
  const uuid = sessionStorage.getItem('adminuuid')
  const token = sessionStorage.getItem('admintoken')
  // 判断请求的类型
  // 如果是post请求就把默认参数拼到data里面
  // 如果是get请求就拼到params里面
  if (config.method === 'post') {
    const data = qs.parse(config.data)

    config.data = qs.stringify({
      admintoken: token,
      adminuuid: uuid,
      ...data
    })
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

axios.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  if (response.data.token) {
    sessionStorage.setItem('admintoken', response.data.token)
  }
  switch (response.data.status) {
    case 0:
      ElementUI.Message.error({ message: response.data.info })
      return response
    case 9:
      ElementUI.Message.error({ message: response.data.info })
      sessionStorage.removeItem('adminuuid')
      sessionStorage.removeItem('adminname')
      sessionStorage.removeItem('admintoken')
      sessionStorage.removeItem('adminislogin')
      sessionStorage.removeItem('adminmenulist')
      router.push('/login')
      break
    default:
      return response
  }
}, function (error) {
  if (error.response) {
    ElementUI.Message.error({ message: error.response.data.message })
  }
})

axios.defaults.withCredentials = true

Vue.prototype.$axios = axios
Vue.prototype.HOST = window.baseUrl
Vue.prototype.WEBNAME = window.webName
Vue.prototype.pagesize = 10
Vue.prototype.pagesizes = ['10', '20', '50', '100', '1000']
Vue.prototype.formLabelWidth = '100px'
Vue.prototype.getDate = function (str) {
  const date = new Date()
  let time
  let weekday
  switch (str) {
    case 'start':
      time =
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1) +
        '-' +
        '01 00:00:00'
      break
    case 'today':
      time =
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1) +
        '-' +
        date.getDate() +
        ' 00:00:00'
      break
    case 'toweek':
      weekday = date.getDay() || 7
      date.setDate(date.getDate() - weekday + 1)
      time = date.getFullYear() +
        '-' +
        (date.getMonth() + 1) +
        '-' +
        date.getDate() +
        ' 00:00:00'
      break
    case 'toweekend':
      weekday = date.getDay() || 7
      date.setDate(date.getDate() - weekday + 7)
      time = date.getFullYear() +
        '-' +
        (date.getMonth() + 1) +
        '-' +
        date.getDate() +
        ' 23:59:59'
      break
    case 'yesterday':
      date.setDate(date.getDate() - 1)
      time =
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1) +
        '-' +
        date.getDate() +
        ' 00:00:00'
      break
    case 'nextyear':
      time =
        (date.getFullYear() + 1) +
        '-' +
        (date.getMonth() + 1) +
        '-' +
        date.getDate() +
        ' 00:00:00'
      break
    default:
      // 当天24点
      time =
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1) +
        '-' +
        date.getDate() +
        ' 23:59:59'
  }
  return time
}
new Vue({
  render: h => h(App),
  router,
  store,
  axios
}).$mount('#app')
